import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import EmailSignUp from "./EmailSignUp"

const Layout = ({ isHomePage, children }) => {
  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <header className="global-header">
        {isHomePage ? (
          <div>
            <Header />
            <div className="text-center max-w-2xl mx-auto my-24">
              <h1 className="text-gray-900 text-5xl font-semibold leading-12 mb-6">
                Stay Up to Date
              </h1>
              <p className="text-lg leading-7">
                Hear about weight loss new articles, tips, tricks, advice!
              </p>
              <EmailSignUp />
              <p class="header-email_subtext">
                When you subscribe, you agree to get emails from me. I don't
                spam, I hate spam as much as you do. I will respect your privacy
                and you can unsubscribe any time.
              </p>
            </div>
          </div>
        ) : (
          <div className="header-background">
            <Header />{" "}
          </div>
        )}
      </header>

      <main>{children}</main>

      <Footer />
    </div>
  )
}

export default Layout
