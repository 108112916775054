import React, { useState } from "react"
import { Link } from "gatsby"
import BlackLogo from "../images/logo-middle-black.png"

const Header = props => {
  const [mobileMenu, setMobileMenu] = useState(false)
  const toggleMenu = e => setMobileMenu(!mobileMenu)

  return (
    <header>
      <div className="bg-primary-600 h-3"></div>
      <div className="container">
        <nav className="py-5 pl-3 flex justify-between">
          <Link to="/">
            <img src={BlackLogo} alt="logo" />
          </Link>

          <ul
            className={
              "sm:translate-x-0 sm:static sm:flex sm:justify-between sm:w-auto pr-6 bg-white-900 transition h-full duration-500 ease-in-out fixed right-0 top-0 pl-2 pr-5 z-10 " +
              (mobileMenu
                ? "transform translate-x-0 w-2/3"
                : "transform translate-x-full w-2/3")
            }
          >
            <li>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 ml-auto sm:hidden"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                onClick={toggleMenu}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </li>
            <li className="sm:ml-6">
              <Link to="/">Home</Link>
            </li>
            <li className="sm:ml-6">
              <Link to="/">About</Link>
            </li>
          </ul>
        </nav>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 absolute top-5 right-3 sm:hidden"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={toggleMenu}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
        <div
          onClick={toggleMenu}
          className={
            mobileMenu === true
              ? "transform translate-x-0 w-2/3 transition duration-500 ease-in-out bg-gray-900 h-full fixed w-32 top-0 opacity-80"
              : "transform -translate-x-full w-2/3"
          }
        ></div>
      </div>
    </header>
  )
}

export default Header
