import React from "react"
import { Link } from "gatsby"
import EmailSignUp from "../components/EmailSignUp"
import WhiteLogo from "../images/logo-middle-white.png"

const Footer = () => {
  const fullYear = new Date().getFullYear()
  return (
    <div className="bg-gray-800 px-20 pt-16">
      <div className="container flex row flex-wrap justify-between">
        <div>
          <img src={WhiteLogo} alt="logo" />
          <p className="text-lg text-gray-200 mt-3 max-w-md">
            Our website services, content, and products are for informational
            purposes only. Caloriejam.com does not provide medical advice,
            diagnosis, or treatment.
          </p>
        </div>
        <div></div>
        <div>
          <ul>
            <li className="text-sm font-semibold text-gray-400">
              Most Popular
            </li>
            <li className="text-lg text-gray-200 mt-4">
              <Link to="/calories-burned-running-calculator">
                Calories Burned Running Calculator
              </Link>
            </li>
            <li className="text-lg text-gray-200 mt-3">
              <Link to="/calories-burned-rowing-calculator">
                Calories Burned Rowing Calculator
              </Link>
            </li>
            <li className="text-lg text-gray-200 mt-3">
              <Link to="/calories-burned-biking-calculator">
                Calories Burned Biking Calculator
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <p className="text-sm font-semibold text-white-900">
            Stay Up To date
          </p>
          <EmailSignUp />
        </div>
      </div>
      <div className="pb-12 mt-12 container flex row justify-between">
        <p className="footer-text">caloriejam.com © {fullYear}</p>
      </div>
    </div>
  )
}

export default Footer
